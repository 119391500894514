// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-data-faculty-js": () => import("./../../../src/pages/data/faculty.js" /* webpackChunkName: "component---src-pages-data-faculty-js" */),
  "component---src-pages-data-h-journal-js": () => import("./../../../src/pages/data/h_journal.js" /* webpackChunkName: "component---src-pages-data-h-journal-js" */),
  "component---src-pages-data-index-js": () => import("./../../../src/pages/data/index.js" /* webpackChunkName: "component---src-pages-data-index-js" */),
  "component---src-pages-data-jstor-js": () => import("./../../../src/pages/data/jstor.js" /* webpackChunkName: "component---src-pages-data-jstor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-practices-alt-js": () => import("./../../../src/pages/practices-alt.js" /* webpackChunkName: "component---src-pages-practices-alt-js" */),
  "component---src-pages-practices-js": () => import("./../../../src/pages/practices.js" /* webpackChunkName: "component---src-pages-practices-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */)
}

